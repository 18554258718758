(function ($) {
	let equal_height = function (container) {
		let currentTallest = 0;
		let currentRowStart = 0;
		let rowDivs = [];
		let $el;
		let topPosition = 0;

		$(container).each(function () {
			$el = $(this);
			$($el).height("auto");
			topPosition = $el.position().top;

			if (currentRowStart !== topPosition) {
				for (
					let currentDiv = 0;
					currentDiv < rowDivs.length;
					currentDiv++
				) {
					rowDivs[currentDiv].height(currentTallest);
				}
				rowDivs.length = 0; // empty the array
				currentRowStart = topPosition;
				currentTallest = $el.height();
				rowDivs.push($el);
			} else {
				rowDivs.push($el);
				currentTallest =
					currentTallest < $el.height()
						? $el.height()
						: currentTallest;
			}
			for (
				let currentDiv = 0;
				currentDiv < rowDivs.length;
				currentDiv++
			) {
				rowDivs[currentDiv].height(currentTallest);
			}
		});
	};

	$(document).ready(function () {
		let $body = $('body');
		let $header = $(".desktop-header,.mobile-header");
		let $util = $header.find(".utility-navigation");
		let headerHeight = $header.outerHeight();
		let utilHeight = $util.outerHeight();

		// start DESKTOP NAVIGATION
		$(document).on("load scroll", function () {
			if ($(document).scrollTop() >= utilHeight) {
				$body.addClass("sticky-header");
			} else {
				$body.removeClass("sticky-header");
			}
		});

		let activePage = window.location.href;
		$(".main-nav a").each(function () {
			let linkPage = this.href;
			if (activePage === linkPage) {
				$(this).parent("li").addClass("active");
			}
		});

		// Allow left/right navigation for top link items of the main navigation
		const desktopNav = Array.from(
			document.getElementsByClassName("mainNav-desktop")
		);
		desktopNav.forEach(function (nav) {
			let topLinks = Array.from(nav.getElementsByClassName("top-link"));
			for (let linkIndex = 0; linkIndex < topLinks.length; linkIndex++) {
				topLinks[linkIndex].addEventListener("keyup", function (event) {
					let newIndex = linkIndex;

					switch (event.keyCode) {
						// Left
						case 37:
							0 < linkIndex ? newIndex-- : null;
							break;
						// Right
						case 39:
							topLinks.length > linkIndex ? newIndex++ : null;
							break;
						default:
							break;
					}

					if (
						newIndex !== linkIndex &&
						"undefined" !== typeof topLinks[newIndex]
					) {
						// Every stops when false is returned
						Array.from(topLinks[newIndex].children).every(function (
							child
						) {
							if ("a" === child.tagName.toLowerCase()) {
								child.focus();
								return false;
							}
						});
					}
				});
			}
		});

		if (767 >= document.documentElement.clientWidth) {
			$(document).on("click", function (e) {
				if ($(e.target).is(".sub-group") === false) {
					$(".sub-group").hide();
					$(".top-link-has-sub").removeClass("active");
				}
			});
		}
		// end DESKTOP NAVIGATION

		// start MOBILE NAVIGATION
		$("#menu-trigger").click(function () {
			$(this).toggleClass("open");
			$(".mobile-header .mobileNav").slideToggle("fast");
		});

		let $mobileSearchButton = $("#search-trigger");
		let $searchBox = $(".mobile-header #searchBox");
		$mobileSearchButton.on("click", function (event) {
			event.preventDefault();

			if (!$searchBox.hasClass("active")) {
				$searchBox.slideDown(400).addClass("active");
				$mobileSearchButton.addClass("active");
			} else {
				$searchBox.slideUp(400).removeClass("active");
				$mobileSearchButton.removeClass("active");
			}
		});

		let winH = $(window).height();
		$("#menu-mobile-nav .menu-item-has-children").click(function (e) {
			$(".menu-item-has-children")
				.not(this)
				.find(".sub-menu")
				.slideUp("fast");
			$(".menu-item-has-children").not(this).removeClass("active");
			$(this).find(".sub-menu").slideDown("fast");
			$(this).addClass("active");
			e.stopPropagation();
		});
		$(".mobileNav").css("height", winH - 72 + 13 + "px");
		// end MOBILE NAVIGATION

		// start HOMEPAGE INDUSTRY TABS
		$(".tab-content, .home-industries .content-container").hide();
		$("ul.tabs li").click(function () {
			let scrollPoint = $(".home-industries").offset().top;
			scrollPoint = scrollPoint - headerHeight;
			$("html,body").animate(
				{
					scrollTop: scrollPoint,
				},
				500
			);
			$("ul.tabs li").removeClass("active");
			$(".home-industries .content-container").show();
			$(this).addClass("active");
			$(".tab-content").hide();
			let activeTab = $(this).data("index");
			$("#" + activeTab).fadeIn("fast");
		});
		$(".home .sustainability .logos-wrap .img-wrap:not(:last-child)").each(
			function () {
				$('<span class="separator"></span>').insertAfter(this);
			}
		);
		$(".logos-wrap > .logo-cell:even").each(function () {
			$(this).next().addBack().wrapAll('<div class="logo-row"></div>');
		});

		$(".service-copy").hide();
		$(".logos-wrap div:first").addClass("active").show();
		$(".service-copy:first").show();
		$(".logos-wrap div").click(function () {
			$(".logos-wrap div").removeClass("active");
			$(this).addClass("active");
			$(".service-copy").hide();
			let activeService = $(this).data("index");
			$("#" + activeService).fadeIn("fast");
		});
		// end HOMEPAGE INDUSTRY TABS

		// start INDUSTRY
		$(".post-type-archive-industries article").matchHeight();
		// end INDUSTRY

		// start BLOG
		$("#posts article").matchHeight();
		// end BLOG

		if ($('body[class*="event"]').length > 0) {
			$(
				'<h1 style="text-align:center;" class="calendarTitle">Calendar of Events</h1>'
			).appendTo(".heroWrap .wrap");
		}

		let $popup_links = jQuery(".open-popup-link");

		// Contact Us Header Popup Link
		$popup_links.magnificPopup({
			type: "inline",
			midClick: true,
			mainClass: "mfp-fade",
		});
		$popup_links.click(function () {
			$(".contact-popup").show();
		});

		equal_height(".post-type-archive-industries article .industry");
		equal_height(".post-type-archive-applications article .industry");
	});
})(window.jQuery);
